import { gql } from '@apollo/client';

export const AUTHENTICATE = gql`
  query authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      id
      uid
      token
      nama_lengkap
      email
      role
      created_at
      updated_at
    }
  }
`;

export const PENGGUNA = gql`
  query pengguna($role: Roles!) {
    pengguna(role: $role) {
      id
      uid
      email
      nama_lengkap
      aktif
      role
      created_at
      updated_at
    }
  }
`;

export const PENGGUNA_BY_UID = gql`
  query findPenggunaByUid($uid: String!) {
    findPenggunaByUid(uid: $uid) {
      id
      uid
      email
      nama_lengkap
      created_at
      updated_at
    }
  }
`;

export const UPDATE_PENGGUNA = gql`
  mutation updatePengguna(
    $uid: String!
    $nama_lengkap: String!
    $email: String!
  ) {
    updatePengguna(uid: $uid, nama_lengkap: $nama_lengkap, email: $email) {
      id
      uid
      email
      nama_lengkap
      created_at
      updated_at
    }
  }
`;

export const CREATE_PENGGUNA = gql`
  mutation createPengguna($data: InPengguna!) {
    createPengguna(data: $data) {
      id
      uid
      email
      nama_lengkap
      created_at
      updated_at
    }
  }
`;

export const PENGGUNA_BY_EMAIL = gql`
  query findPenggunaByEmail($email: String!) {
    findPenggunaByEmail(email: $email) {
      id
      uid
      email
      nama_lengkap
      created_at
      updated_at
    }
  }
`;

export const VERIFIKASI_PENGGUNA = gql`
  mutation verifikasiPengguna($uid: String!) {
    verifikasiPengguna(uid: $uid) {
      id
      uid
      email
      nama_lengkap
      created_at
      updated_at
    }
  }
`;
