import React, { useEffect, useState, useContext } from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import GlobalContext from '../../utils/GlobalContext';
import style from '../../utils/style';
import { setError } from '../../utils';
import {
  CREATE_PENGGUNA,
  PENGGUNA_BY_EMAIL,
  PENGGUNA_BY_UID,
  UPDATE_PENGGUNA,
} from '../../graphql/Pengguna';

const WaliForm = (props) => {
  const { history, query, mutate, isAdd, match } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [email, setEmail] = useState('');
  const [namaLengkap, setNamaLengkap] = useState('');
  const classes = style();

  useEffect(() => {
    if (match.params.uid) {
      setGlobalLoading(true);

      const getPenggunaByUid = async () => {
        const {
          data: { findPenggunaByUid },
        } = await query({
          query: PENGGUNA_BY_UID,
          variables: {
            uid: match.params.uid,
          },
          fetchPolicy: 'no-cache',
        });

        setEmail(findPenggunaByUid.email);
        setNamaLengkap(findPenggunaByUid.nama_lengkap);
        setGlobalLoading(false);
      };
      getPenggunaByUid();
    }
  }, [match.params.uid, query, setGlobalLoading]);

  const AddPage = () => (
    <React.Fragment>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              email,
              nama_lengkap: namaLengkap,
            }}
            validationSchema={Yup.object().shape({
              nama_lengkap: Yup.string().required('Harus diisi'),
              email: Yup.string()
                .email('Email tidak valid')
                .required('Harus diisi'),
            })}
            onSubmit={async ({ email, nama_lengkap }, { resetForm }) => {
              const {
                data: { findPenggunaByEmail },
              } = await query({
                query: PENGGUNA_BY_EMAIL,
                variables: {
                  email,
                },
                fetchPolicy: 'no-cache',
              });

              if (findPenggunaByEmail) {
                setSnack({
                  variant: 'error',
                  message: `Email ${email} sudah terdaftar di sistem.`,
                  opened: true,
                });

                return false;
              }

              try {
                setGlobalLoading(true);
                const {
                  data: { createPengguna },
                } = await mutate({
                  mutation: CREATE_PENGGUNA,
                  variables: {
                    data: {
                      nama_lengkap,
                      email,
                      password: new Date().getTime().toString(),
                      role: 'USER',
                    },
                  },
                  fetchPolicy: 'no-cache',
                });

                setSnack({
                  variant: 'success',
                  message: `Pengguna ${createPengguna.email} Berhasil ditambah!`,
                  opened: true,
                });
                setGlobalLoading(false);
                resetForm();
                history.replace('/wali');
              } catch (error) {
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values, handleChange }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoFocus
                        margin="normal"
                        fullWidth
                        id="nama_lengkap"
                        label="Nama Lengkap"
                        name="nama_lengkap"
                        // onChange={(e) => setNamaLengkap(e.target.value)}
                        onChange={handleChange}
                        value={values.nama_lengkap}
                      />
                      {errors.nama_lengkap && touched.nama_lengkap && (
                        <div style={{ color: 'red' }}>
                          {errors.nama_lengkap}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        // onChange={(e) => setEmail(e.target.value)}
                        onChange={handleChange}
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <div style={{ color: 'red' }}>{errors.email}</div>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/wali')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const EditPage = () => (
    <React.Fragment>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              email,
              nama_lengkap: namaLengkap,
            }}
            validationSchema={Yup.object().shape({
              nama_lengkap: Yup.string().required('Harus diisi'),
              email: Yup.string()
                .email('Email tidak valid')
                .required('Harus diisi'),
            })}
            onSubmit={async ({ email, nama_lengkap }, { resetForm }) => {
              const {
                data: { findPenggunaByEmail },
              } = await query({
                query: PENGGUNA_BY_EMAIL,
                variables: {
                  email,
                },
                fetchPolicy: 'no-cache',
              });

              if (findPenggunaByEmail) {
                setSnack({
                  variant: 'error',
                  message: `Email ${email} sudah terdaftar di sistem.`,
                  opened: true,
                });

                return false;
              }

              try {
                setGlobalLoading(true);
                const {
                  data: { updatePengguna },
                } = await mutate({
                  mutation: UPDATE_PENGGUNA,
                  variables: {
                    uid: match.params.uid,
                    nama_lengkap,
                    email,
                  },
                  fetchPolicy: 'no-cache',
                });
                setSnack({
                  variant: 'success',
                  message: `Pengguna ${updatePengguna.email} Berhasil diperbarui`,
                  opened: true,
                });
                setGlobalLoading(false);
                resetForm();
                history.replace('/wali');
              } catch (error) {
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values, handleChange }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoFocus
                        margin="normal"
                        fullWidth
                        id="nama_lengkap"
                        label="Nama Lengkap"
                        name="nama_lengkap"
                        onChange={handleChange}
                        value={values.nama_lengkap}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.nama_lengkap && touched.nama_lengkap && (
                        <div style={{ color: 'red' }}>
                          {errors.nama_lengkap}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.email && touched.email && (
                        <div style={{ color: 'red' }}>{errors.email}</div>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/wali')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return <div>{isAdd ? <AddPage /> : <EditPage />}</div>;
};

export default WaliForm;
