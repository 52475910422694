import { gql } from '@apollo/client';

export const TAHUN_AJARAN = gql`
  query tahunAjaran {
    tahunAjaran {
      id
      tid
      periode_awal
      periode_akhir
      semester
      aktif
      created_at
    }
  }
`;

export const TAHUN_AJARAN_BY_TID = gql`
  query findTahunAjaranByTid($tid: String!) {
    findTahunAjaranByTid(tid: $tid) {
      id
      tid
      periode_awal
      periode_akhir
      semester
      aktif
      created_at
    }
  }
`;

export const CREATE_TAHUN_AJARAN = gql`
  mutation createTahunAjaran($data: InTahunAjaran!) {
    createTahunAjaran(data: $data) {
      id
      tid
      periode_awal
      periode_akhir
      semester
      aktif
      created_at
    }
  }
`;

export const FIND_TAHUN_AJARAN_BY_PERIODE = gql`
  query findTahunAjaranByPeriode($data: InTahunAjaran!) {
    findTahunAjaranByPeriode(data: $data) {
      id
      tid
      periode_awal
      periode_akhir
      semester
      aktif
      created_at
    }
  }
`;

export const UPDATE_TAHUN_AJARAN = gql`
  mutation updateTahunAjaran($tid: String!, $data: InTahunAjaran!) {
    updateTahunAjaran(tid: $tid, data: $data) {
      id
      tid
      periode_awal
      periode_akhir
      semester
      aktif
      created_at
    }
  }
`;
