import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import GlobalContext from '../../utils/GlobalContext';
import style from '../../utils/style';
import { setError } from '../../utils';
import {
  CREATE_TAHUN_AJARAN,
  FIND_TAHUN_AJARAN_BY_PERIODE,
  TAHUN_AJARAN_BY_TID,
  UPDATE_TAHUN_AJARAN,
} from '../../graphql/TahunAjaran';

const TahunAjaranForm = (props) => {
  const { history, query, mutate, isAdd, match } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [periodeAwal, setPeriodeAwal] = useState('');
  const [periodeAkhir, setPeriodeAkhir] = useState('');
  const [semester, setSemester] = useState('');
  const classes = style();

  const currentYear = new Date();
  const years = [
    currentYear.getFullYear(),
    currentYear.getFullYear() + 1,
    currentYear.getFullYear() + 2,
  ];

  useEffect(() => {
    if (match.params.tid) {
      setGlobalLoading(true);

      const getMataPelajaran = async () => {
        const {
          data: { findTahunAjaranByTid },
        } = await query({
          query: TAHUN_AJARAN_BY_TID,
          variables: {
            tid: match.params.tid,
          },
          fetchPolicy: 'no-cache',
        });

        setPeriodeAwal(findTahunAjaranByTid.periode_awal);
        setPeriodeAkhir(findTahunAjaranByTid.periode_akhir);
        setSemester(findTahunAjaranByTid.semester);
        setGlobalLoading(false);
      };
      getMataPelajaran();
    }
  }, [match.params.tid, query, setGlobalLoading]);

  const AddPage = () => (
    <React.Fragment>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              periode_awal: periodeAwal,
              periode_akhir: periodeAkhir,
              semester,
            }}
            validationSchema={Yup.object().shape({
              periode_awal: Yup.number().required('Harus diisi'),
              periode_akhir: Yup.number()
                .moreThan(
                  Yup.ref('periode_awal'),
                  'Periode akhir tidak boleh kurang dari Periode Awal'
                )
                .required('Harus diisi'),
              semester: Yup.number().required('Harus diisi'),
            })}
            onSubmit={async (
              { periode_awal, periode_akhir, semester },
              { resetForm }
            ) => {
              const {
                data: { findTahunAjaranByPeriode },
              } = await query({
                query: FIND_TAHUN_AJARAN_BY_PERIODE,
                variables: {
                  data: { periode_awal, periode_akhir, semester },
                },
                fetchPolicy: 'no-cache',
              });

              if (findTahunAjaranByPeriode) {
                setSnack({
                  variant: 'error',
                  message: 'Tahun Ajaran sudah ada',
                  opened: true,
                });

                return false;
              }

              try {
                setGlobalLoading(true);
                const {
                  data: { createTahunAjaran },
                } = await mutate({
                  mutation: CREATE_TAHUN_AJARAN,
                  variables: {
                    data: { periode_awal, periode_akhir, semester },
                  },
                  fetchPolicy: 'no-cache',
                });

                setSnack({
                  variant: 'success',
                  message: `Tahun Ajaran ${createTahunAjaran.periode_awal} - ${createTahunAjaran.periode_akhir} Berhasil disimpan`,
                  opened: true,
                });

                setGlobalLoading(false);
                resetForm();
                history.replace('/tahun_ajaran');
              } catch (error) {
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values, handleChange }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="periode_awal">Periode Awal</InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="periode_awal"
                          name="periode_awal"
                          onChange={(e) => setPeriodeAwal(e.target.value)}
                          value={values.periode_awal}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {years.length
                            ? years.map((x) => (
                                <MenuItem value={x}>{x}</MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                      {errors.periode_awal && touched.periode_awal && (
                        <div style={{ color: 'red' }}>
                          {errors.periode_awal}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="periode_akhir">
                          Periode Akhir
                        </InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="periode_akhir"
                          name="periode_akhir"
                          onChange={(e) => setPeriodeAkhir(e.target.value)}
                          value={values.periode_akhir}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {years.length
                            ? years.map((x) => (
                                <MenuItem value={x}>{x}</MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                      {errors.periode_akhir && touched.periode_akhir && (
                        <div style={{ color: 'red' }}>
                          {errors.periode_akhir}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="semester">Semester</InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="semester"
                          name="semester"
                          onChange={(e) => setSemester(e.target.value)}
                          value={values.semester}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.semester && touched.semester && (
                        <div style={{ color: 'red' }}>{errors.semester}</div>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/tahun_ajaran')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const EditPage = () => (
    <React.Fragment>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              periode_awal: periodeAwal,
              periode_akhir: periodeAkhir,
              semester,
            }}
            validationSchema={Yup.object().shape({
              periode_awal: Yup.number().required('Harus diisi'),
              periode_akhir: Yup.number()
                .moreThan(
                  Yup.ref('periode_awal'),
                  'Periode akhir tidak boleh kurang dari Periode Awal'
                )
                .required('Harus diisi'),
              semester: Yup.number().required('Harus diisi'),
            })}
            onSubmit={async (
              { periode_awal, periode_akhir, semester },
              { resetForm }
            ) => {
              const {
                data: { findTahunAjaranByPeriode },
              } = await query({
                query: FIND_TAHUN_AJARAN_BY_PERIODE,
                variables: {
                  data: { periode_awal, periode_akhir, semester },
                },
                fetchPolicy: 'no-cache',
              });
              console.log(findTahunAjaranByPeriode);
              if (findTahunAjaranByPeriode) {
                setSnack({
                  variant: 'error',
                  message: 'Tahun Ajaran sudah ada',
                  opened: true,
                });

                return false;
              }

              try {
                setGlobalLoading(true);
                const {
                  data: { updateTahunAjaran },
                } = await mutate({
                  mutation: UPDATE_TAHUN_AJARAN,
                  variables: {
                    tid: match.params.tid,
                    data: { periode_awal, periode_akhir, semester },
                  },
                  fetchPolicy: 'no-cache',
                });

                setSnack({
                  variant: 'success',
                  message: `Tahun Ajaran ${updateTahunAjaran.periode_awal} - ${updateTahunAjaran.periode_akhir} Berhasil disimpan`,
                  opened: true,
                });

                setGlobalLoading(false);
                resetForm();
                history.replace('/tahun_ajaran');
              } catch (error) {
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values, handleChange }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="periode_awal">Periode Awal</InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="periode_awal"
                          name="periode_awal"
                          onChange={(e) => setPeriodeAwal(e.target.value)}
                          value={values.periode_awal}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {years.length
                            ? years.map((x) => (
                                <MenuItem value={x}>{x}</MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                      {errors.periode_awal && touched.periode_awal && (
                        <div style={{ color: 'red' }}>
                          {errors.periode_awal}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="periode_akhir">
                          Periode Akhir
                        </InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="periode_akhir"
                          name="periode_akhir"
                          onChange={(e) => setPeriodeAkhir(e.target.value)}
                          value={values.periode_akhir}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {years.length
                            ? years.map((x) => (
                                <MenuItem value={x}>{x}</MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                      {errors.periode_akhir && touched.periode_akhir && (
                        <div style={{ color: 'red' }}>
                          {errors.periode_akhir}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="semester">Semester</InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="semester"
                          name="semester"
                          onChange={(e) => setSemester(e.target.value)}
                          value={values.semester}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.semester && touched.semester && (
                        <div style={{ color: 'red' }}>{errors.semester}</div>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/tahun_ajaran')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );
  return <div>{isAdd ? <AddPage /> : <EditPage />}</div>;
};

export default TahunAjaranForm;
