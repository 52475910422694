import React from 'react';
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik } from 'formik';

import style from '../../utils/style';

const FormA = (props) => {
  const classes = style();
  const { formValues, handleNext } = props;

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Formik
          enableReinitialize
          initialValues={{
            ksk: formValues ? formValues.ksk : '',
            nama_lengkap: formValues ? formValues.nama_lengkap : '',
            nama_panggilan: formValues ? formValues.nama_panggilan : '',
            jenis_kelamin: formValues?.jenis_kelamin
              ? formValues.jenis_kelamin
              : '',
            tempat_lahir: formValues ? formValues.tempat_lahir : '',
            tanggal_lahir: formValues
              ? moment(formValues.tanggal_lahir).format('YYYY-MM-DD')
              : '',
            alamat_anak: formValues?.alamat_anak ? formValues.alamat_anak : '',
            no_telepon_anak: formValues?.no_telepon_anak
              ? formValues.no_telepon_anak
              : '',
            agama: formValues?.agama ? formValues.agama : '',
            warga_negara: formValues ? formValues.warga_negara : '',
            anak_ke: formValues ? formValues.anak_ke : 1,
            saudara_kandung: formValues ? formValues.saudara_kandung : 0,
            saudara_tiri: formValues ? formValues.saudara_tiri : 0,
            saudara_angkat: formValues ? formValues.saudara_angkat : 0,
            status_anak: formValues?.status_anak ? formValues.status_anak : '',
            bahasa_sehari: formValues ? formValues.bahasa_sehari : '',
            asal_sekolah: formValues ? formValues.asal_sekolah : '',
            kelainan_jasmani: formValues ? formValues.kelainan_jasmani : '',
          }}
          validationSchema={Yup.object().shape({
            ksk: Yup.string().required('Harus diisi'),
            nama_lengkap: Yup.string().required('Harus diisi'),
            nama_panggilan: Yup.string().required('Harus diisi'),
            jenis_kelamin: Yup.string().required('Harus diisi'),
            tempat_lahir: Yup.string().required('Harus diisi'),
            tanggal_lahir: Yup.date().required('Harus diisi'),
            agama: Yup.string().required('Harus diisi'),
            warga_negara: Yup.string().required('Harus diisi'),
            anak_ke: Yup.number().required('Harus diisi'),
            saudara_kandung: Yup.number().required('Harus diisi'),
            status_anak: Yup.string().required('Harus diisi'),
            bahasa_sehari: Yup.string().required('Harus diisi'),
          })}
        >
          {({
            errors,
            touched,
            setTouched,
            validateForm,
            values,
            handleChange,
          }) => (
            <form noValidate>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      autoFocus
                      margin="normal"
                      fullWidth
                      id="nama_lengkap"
                      label="Nama Lengkap Peserta Didik"
                      name="nama_lengkap"
                      onChange={handleChange}
                      value={values.nama_lengkap}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.nama_lengkap && touched.nama_lengkap && (
                      <div style={{ color: 'red' }}>{errors.nama_lengkap}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="nama_panggilan"
                      label="Nama Panggilan"
                      name="nama_panggilan"
                      onChange={handleChange}
                      value={values.nama_panggilan}
                      InputLabelProps={{
                        shrink: values.nama_panggilan ? true : false,
                      }}
                    />
                    {errors.nama_panggilan && touched.nama_panggilan && (
                      <div style={{ color: 'red' }}>
                        {errors.nama_panggilan}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="ksk"
                      label="Nomor KSK"
                      name="ksk"
                      onChange={handleChange}
                      value={values.ksk}
                      InputLabelProps={{
                        shrink: values.ksk ? true : false,
                      }}
                    />
                    {errors.ksk && touched.ksk && (
                      <div style={{ color: 'red' }}>{errors.ksk}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Jenis Kelamin</FormLabel>
                      <RadioGroup
                        aria-label="jenis_kelamin"
                        name="jenis_kelamin"
                        value={values.jenis_kelamin}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="Laki-laki"
                          control={<Radio color="primary" />}
                          label="Laki-laki"
                        />
                        <FormControlLabel
                          value="Perempuan"
                          control={<Radio color="primary" />}
                          label="Perempuan"
                        />
                      </RadioGroup>
                    </FormControl>
                    {errors.jenis_kelamin && touched.jenis_kelamin && (
                      <div style={{ color: 'red' }}>{errors.jenis_kelamin}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="tempat_lahir"
                      label="Tempat Lahir"
                      name="tempat_lahir"
                      onChange={handleChange}
                      value={values.tempat_lahir}
                      InputLabelProps={{
                        shrink: values.tempat_lahir ? true : false,
                      }}
                    />
                    {errors.tempat_lahir && touched.tempat_lahir && (
                      <div style={{ color: 'red' }}>{errors.tempat_lahir}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="tanggal_lahir"
                      label="Tanggal Lahir"
                      name="tanggal_lahir"
                      onChange={handleChange}
                      value={values.tanggal_lahir}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.tanggal_lahir && touched.tanggal_lahir && (
                      <div style={{ color: 'red' }}>{errors.tanggal_lahir}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="alamat_anak"
                      label="Alamat Anak"
                      name="alamat_anak"
                      onChange={handleChange}
                      value={values.alamat_anak}
                      multiline
                      rows={4}
                    />
                    {errors.alamat_anak && touched.alamat_anak && (
                      <div style={{ color: 'red' }}>{errors.alamat_anak}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="no_telepon_anak"
                      label="Nomor Telepon / HP"
                      name="no_telepon_anak"
                      onChange={handleChange}
                      value={values.no_telepon_anak}
                    />
                    {errors.no_telepon_anak && touched.no_telepon_anak && (
                      <div style={{ color: 'red' }}>
                        {errors.no_telepon_anak}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="anak_ke"
                      label="Anak Keberapa"
                      name="anak_ke"
                      onChange={handleChange}
                      value={values.anak_ke}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.anak_ke && touched.anak_ke && (
                      <div style={{ color: 'red' }}>{errors.anak_ke}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="jumlah_saudara"
                      label="Jumlah Saudara"
                      name="jumlah_saudara"
                      onChange={handleChange}
                      value={values.jumlah_saudara}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.jumlah_saudara && touched.jumlah_saudara && (
                      <div style={{ color: 'red' }}>
                        {errors.jumlah_saudara}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="saudara_kandung"
                      label="Jumlah Saudara Kandung"
                      name="saudara_kandung"
                      onChange={handleChange}
                      value={values.saudara_kandung}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.saudara_kandung && touched.saudara_kandung && (
                      <div style={{ color: 'red' }}>
                        {errors.saudara_kandung}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="saudara_tiri"
                      label="Jumlah Saudara Tiri"
                      name="saudara_tiri"
                      onChange={handleChange}
                      value={values.saudara_tiri}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="saudara_angkat"
                      label="Jumlah Saudara Angkat"
                      name="saudara_angkat"
                      onChange={handleChange}
                      value={values.saudara_angkat}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Status Siswa</FormLabel>
                      <RadioGroup
                        aria-label="status_anak"
                        name="status_anak"
                        value={values.status_anak}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="Anak Yatim"
                          control={<Radio color="primary" />}
                          label="Yatim"
                        />
                        <FormControlLabel
                          value="Anak Piatu"
                          control={<Radio color="primary" />}
                          label="Piatu"
                        />
                        <FormControlLabel
                          value="Anak Yatim Piatu"
                          control={<Radio color="primary" />}
                          label="Yatim Piatu"
                        />
                        <FormControlLabel
                          value="Tidak Yatim Piatu"
                          control={<Radio color="primary" />}
                          label="Tidak Yatim Piatu"
                        />
                      </RadioGroup>
                    </FormControl>
                    {errors.status_anak && touched.status_anak && (
                      <div style={{ color: 'red' }}>{errors.status_anak}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="bahasa_sehari"
                      label="Bahasa sehari-hari di rumah"
                      name="bahasa_sehari"
                      onChange={handleChange}
                      value={values.bahasa_sehari}
                      InputLabelProps={{
                        shrink: values.bahasa_sehari ? true : false,
                      }}
                    />
                    {errors.bahasa_sehari && touched.bahasa_sehari && (
                      <div style={{ color: 'red' }}>{errors.bahasa_sehari}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="warga_negara"
                      label="Kewarganegaraan"
                      name="warga_negara"
                      onChange={handleChange}
                      value={values.warga_negara}
                      InputLabelProps={{
                        shrink: values.warga_negara ? true : false,
                      }}
                    />
                    {errors.warga_negara && touched.warga_negara && (
                      <div style={{ color: 'red' }}>{errors.warga_negara}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="agama">Agama</InputLabel>
                      <Select
                        margin="normal"
                        fullWidth
                        id="agama"
                        label="Agama"
                        name="agama"
                        onChange={handleChange}
                        value={values.agama}
                      >
                        <MenuItem value="" disabled>
                          --- Pilih ---
                        </MenuItem>
                        <MenuItem value="Islam">Islam</MenuItem>
                        <MenuItem value="Kristen">Kristen</MenuItem>
                        <MenuItem value="Hindu">Hindu</MenuItem>
                        <MenuItem value="Buddha">Buddha</MenuItem>
                      </Select>
                    </FormControl>
                    {errors.agama && touched.agama && (
                      <div style={{ color: 'red' }}>{errors.agama}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="kelainan_jasmani"
                      label="Kelainan Jasmani"
                      name="kelainan_jasmani"
                      onChange={handleChange}
                      value={values.kelainan_jasmani}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="asal_sekolah"
                      label="Asal Sekolah"
                      name="asal_sekolah"
                      onChange={handleChange}
                      value={values.asal_sekolah}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <div>
                <br />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    validateForm().then((errors) => {
                      if (
                        Object.entries(errors).length === 0 &&
                        errors.constructor === Object
                      ) {
                        handleNext(values);
                      } else {
                        setTouched(errors);
                      }
                    })
                  }
                >
                  Lanjut
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default FormA;
