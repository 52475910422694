import { Typography } from '@material-ui/core';
import React from 'react';

const VisiMisiPage = () => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        <b>Visi:</b>
      </Typography>
      <Typography variant="h6" gutterBottom>
        Berakhlak mulia, mandiri, terampil, dan kreatif, serta berkarakter.
      </Typography>
      <Typography variant="h5" gutterBottom>
        <b>Misi:</b>
      </Typography>
      <Typography variant="h6">
        1. <b>Meningkatkan</b> ketaqwaan pada Tuhan Yang Maha Esa
      </Typography>
      <Typography variant="h6">
        2. <b>Menciptakan</b> sikap dan kepribadian yang mandiri tanpa
        tergantung pada orang lain
      </Typography>
      <Typography variant="h6">
        3. <b>Meningkatkan</b> siswa yang terampil dan kreatif
      </Typography>
      <Typography variant="h6">
        4. <b>Membentuk</b> siswa untuk selalu bersikap positif
      </Typography>
    </div>
  );
};

export default VisiMisiPage;
