import React, { useState, useEffect, useContext } from 'react';
import { Chip } from '@material-ui/core';
import MyTable from '../MyTable';
import MyModal from '../MyModal';
import { PENGGUNA } from '../../graphql/Pengguna';
import GlobalContext from '../../utils/GlobalContext';

const WaliPage = (props) => {
  const { query, history } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [wali, setWali] = useState([]);
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = React.useState(false);
  const COLUMNS = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
    },
    { title: 'uid', field: 'uid', hidden: true },
    { title: 'Email', field: 'email' },
    { title: 'Nama Lengkap', field: 'nama_lengkap' },
    {
      title: 'Peran',
      field: 'role',
      render: (rowData) => <Chip label={rowData.role} color="primary" />,
    },
    {
      title: 'Status',
      field: 'aktif',
      render: (rowData) => (
        <Chip
          label={rowData.aktif ? 'Aktif' : 'Tidak Aktif'}
          color={rowData.aktif ? 'primary' : 'secondary'}
        />
      ),
    },
    {
      title: 'Tanggal Dibuat',
      field: 'created_at',
      type: 'datetime',
    },
  ];
  const TITLE = 'Pengguna';

  useEffect(() => {
    setGlobalLoading(true);
    const getPengguna = async () => {
      const {
        data: { pengguna },
      } = await query({
        query: PENGGUNA,
        variables: {
          role: 'USER',
        },
        fetchPolicy: 'no-cache',
      });
      setWali(pengguna);
    };

    getPengguna();
    setGlobalLoading(false);
  }, [query, setGlobalLoading]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <MyTable
        history={history}
        columns={COLUMNS}
        data={wali}
        title={TITLE}
        addUrl="/wali/add"
        editUrl="/wali"
        keyColumn="uid"
        setOpen={setOpen}
        setTempData={setTempData}
      />
      <MyModal
        open={open}
        handleClose={handleClose}
        data={tempData}
        type="Pengguna"
        mappingRows={{
          id: 'ID Pengguna',
          email: 'Email',
          nama_lengkap: 'Nama Lengkap',
          role: 'Peran',
          created_at: 'Tanggal Dibuat',
        }}
      />
    </React.Fragment>
  );
};

export default WaliPage;
