import { Typography } from '@material-ui/core';
import React from 'react';

const SyaratPage = () => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Syarat Pendaftaran
      </Typography>
      <Typography variant="h6">1. TK Usia minimal 5 tahun</Typography>
      <Typography variant="h6">2. Fotocopy Akta Kelahiran</Typography>
      <Typography variant="h6">
        {' '}
        3. Fotocopy Kartu Keluarga (KK) 1 Lembar
      </Typography>
    </div>
  );
};

export default SyaratPage;
