import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import GlobalContext from '../../utils/GlobalContext';
import { setError } from '../../utils';
import {
  REGISTRASI_SISWA,
  CHECK_SISWA,
  SISWA_BY_NIS,
  UPDATE_SISWA,
} from '../../graphql/Siswa';

import FormA from './FormA';
import FormB from './FormB';
import FormC from './FormC';
import FormD from './FormD';
import FormE from './FormE';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    margin: 'auto',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(3, 2),
  },
}));

const getSteps = () => {
  return [
    'Identitas Murid',
    'Identitas Orang Tua',
    'Identitas Orang Wali (diisi bila Murid mengikuti Wali / tidak mengikuti Orang Tua)',
    'Keterangan Lain Anak',
    'Imunisasi Sebelum Masuk Sekolah',
  ];
};
const cookies = new Cookies();

export default function MyStepper(props) {
  const { query, mutate, history, edit, match } = props;

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({});
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [siswa, setSiswa] = useState({});
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  useEffect(() => {
    setGlobalLoading(true);

    const getPenggunaByUid = async () => {
      const {
        data: { checkDataRegister },
      } = await query({
        query: CHECK_SISWA,
        variables: {
          uid: cookies.get('uid'),
        },
        fetchPolicy: 'no-cache',
      });
      console.log('checkDataRegister', checkDataRegister);
      setSiswa(checkDataRegister);
      setGlobalLoading(false);
    };
    getPenggunaByUid();
  }, [query, setGlobalLoading]);

  useEffect(() => {
    if (edit && match.params.nis) {
      setGlobalLoading(true);

      const getSiswa = async () => {
        const {
          data: { siswaByNis },
        } = await query({
          query: SISWA_BY_NIS,
          variables: {
            nis: match.params.nis,
          },
          fetchPolicy: 'no-cache',
        });

        setGlobalLoading(false);
        setFormValues(siswaByNis);
      };

      getSiswa();
    }
  }, [edit, match.params.nis, query, setGlobalLoading]);

  const handleNext = (newValues) => {
    setFormValues({ ...formValues, ...newValues });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const _handleSubmit = async (values) => {
    try {
      setGlobalLoading(true);

      if (edit && match.params.nis) {
        delete formValues.__typename;
        delete formValues.nis;
        delete formValues.pengguna_id;

        const {
          data: { updateSiswa },
        } = await mutate({
          mutation: UPDATE_SISWA,
          variables: {
            data: { ...formValues, ...values },
            nis: match.params.nis,
          },
        });

        setSnack({
          variant: 'success',
          message: `${updateSiswa.nama_lengkap} Berhasil diubah`,
          opened: true,
        });
      } else {
        formValues.uid = cookies.get('uid');

        const {
          data: { registrasiSiswa },
        } = await mutate({
          mutation: REGISTRASI_SISWA,
          variables: { data: formValues, uid: cookies.get('uid') },
        });

        setSnack({
          variant: 'success',
          message: `${registrasiSiswa.nama_lengkap} Berhasil disimpan`,
          opened: true,
        });
      }

      setGlobalLoading(false);

      if (cookies.get('role') === 'USER') {
        history.replace('/register');
      } else {
        history.replace('/siswa');
      }
    } catch (error) {
      setGlobalLoading(false);
      setError(setSnack, error);
    }
  };

  const getStepContent = (stepIndex, classes) => {
    const isLastStep = activeStep === steps.length - 1;

    switch (stepIndex) {
      case 0:
        return (
          <FormA
            formValues={formValues}
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            handleNext={handleNext}
            edit={edit}
          />
        );

      case 1:
        return (
          <FormB
            formValues={formValues}
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            handleNext={handleNext}
            edit={edit}
          />
        );

      case 2:
        return (
          <FormC
            formValues={formValues}
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            handleNext={handleNext}
            edit={edit}
          />
        );

      case 3:
        return (
          <FormD
            formValues={formValues}
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            handleNext={handleNext}
            edit={edit}
          />
        );

      case 4:
        return (
          <FormE
            formValues={formValues}
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            handleNext={handleNext}
            edit={edit}
            _handleSubmit={_handleSubmit}
          />
        );

      default:
        return 'Uknown stepIndex';
    }
  };

  return (
    <div className={classes.root}>
      {cookies.get('role') === 'USER' && siswa ? (
        <>
          <Typography variant="h6">
            Anda sudah mendaftarkan siswa, silahkan menghubungi Bunda Emi
            (082245405777)
          </Typography>
        </>
      ) : (
        <>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep, classes)}
                  {/* {isLastStep && (
                    <Button
                      onClick={_handleSubmit}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Simpan
                    </Button>
                  )} */}
                </Typography>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
