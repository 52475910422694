import React, { useState, useEffect, useContext } from 'react';
import { Chip, IconButton, Tooltip } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import MyTable from '../MyTable';
import MyModal from '../MyModal';
import { SISWA_HOME, SISWA_BY_NIS, SISWA } from '../../graphql/Siswa';
import GlobalContext from '../../utils/GlobalContext';
import pdfSiswa from '../Laporan/Siswa';
import pdfAllSiswa from '../Laporan/AllSiswa';

const SiswaPage = (props) => {
  const { query, history } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [siswa, setSiswa] = useState([]);
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = useState(false);

  const COLUMNS = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
    },
    { title: 'Nomor Induk Siswa', field: 'nis' },
    { title: 'Nama Lengkap', field: 'nama_lengkap' },
    { title: 'Nama Ayah / Wali', field: 'nama_ayah' },
    {
      title: 'Status',
      field: 'aktif',
      render: (rowData) => (
        <Chip
          label={rowData.aktif ? 'Aktif' : 'Tidak Aktif'}
          color={rowData.aktif ? 'primary' : 'secondary'}
        />
      ),
    },
    {
      title: 'Tanggal Pendaftaran',
      field: 'created_at',
      type: 'datetime',
    },
    // {
    //   title: 'Cetak Data',
    //   render: (rowData) => {
    //     return (
    //       <>
    //         <Tooltip title="Cetak PDF Siswa">
    //           <IconButton
    //             aria-label="print"
    //             onClick={() => pdfSiswa(rowData, query, { SISWA_BY_NIS })}
    //           >
    //             <PrintIcon />
    //           </IconButton>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
  ];
  const TITLE = 'Siswa';

  useEffect(() => {
    setGlobalLoading(true);
    const getSiswa = async () => {
      const {
        data: { siswa },
      } = await query({
        query: SISWA_HOME,
        fetchPolicy: 'no-cache',
      });
      setSiswa(siswa);
      setShow(true);
    };

    getSiswa();
    setGlobalLoading(false);
  }, [query, setGlobalLoading]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {show && (
        <div
          style={{
            float: 'right',
            zIndex: 1000,
            position: 'relative',
            marginTop: '10px',
          }}
        >
          <>
            <Tooltip title="Cetak Semua Data Siswa">
              <IconButton
                style={{ paddingLeft: '0', marginRight: '7px' }}
                aria-label="print"
                onClick={() => pdfAllSiswa(query, SISWA)}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          </>
        </div>
      )}
      <MyTable
        history={history}
        columns={COLUMNS}
        data={siswa}
        title={TITLE}
        addUrl="/siswa/add"
        editUrl="/siswa/edit"
        keyColumn="nis"
        setOpen={setOpen}
        setTempData={setTempData}
        query={query}
        schema={SISWA_BY_NIS}
        pdf={pdfSiswa}
      />
      <MyModal
        open={open}
        handleClose={handleClose}
        data={tempData}
        type="Siswa"
        mappingRows={{
          id: 'ID Siswa',
          nis: 'Nomor Induk Siswa',
          nama_lengkap: 'Nama Lengkap',
          nama_ayah: 'Nama Ayah',
          created_at: 'Tanggal Pendaftaran',
        }}
      />
    </React.Fragment>
  );
};

export default SiswaPage;
