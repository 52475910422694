import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloConsumer,
} from '@apollo/client';
import { GlobalProvider } from './utils/GlobalContext';
import MyRouter from './components/MyRouter';

const client = new ApolloClient({
  uri: 'https://tkfajar-gql.agitanggria.one/data',
  cache: new InMemoryCache(),
});

function App() {
  const [snack, setSnack] = useState({
    variant: 'success',
    message: null,
    opened: false,
  });
  const [globalLoading, setGlobalLoading] = useState(false);

  return (
    <GlobalProvider value={{ setSnack, setGlobalLoading }}>
      <ApolloProvider client={client}>
        <ApolloConsumer>
          {(client) => (
            <MyRouter
              client={client}
              snack={snack}
              globalLoading={globalLoading}
            />
          )}
        </ApolloConsumer>
      </ApolloProvider>
    </GlobalProvider>
  );
}

export default App;
