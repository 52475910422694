import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import {
  Dashboard,
  Menu as MenuIcon,
  PowerSettingsNew,
  PermIdentity,
  CalendarToday,
  SupervisedUserCircle,
  Assignment,
} from '@material-ui/icons';

import PenggunaPage from './Pengguna/PenggunaPage';
import PenggunaForm from './Pengguna/PenggunaForm';

import WaliPage from './Pengguna/WaliPage';
import WaliForm from './Pengguna/WaliForm';

import TahunAjaranPage from './TahunAjaran/TahunAjaranPage';
import TahunAjaranForm from './TahunAjaran/TahunAjaranForm';

import SiswaPage from './Siswa/SiswaPage';
import RegistrasiPage from './Siswa/RegistrasiPage';
import VerificationSiswa from './Siswa/VerificationSiswa';

import SyaratPage from './HomePage/SyaratPage';
import VisiMisiPage from './HomePage/VisiMisiPage';
import FasilitasPage from './HomePage/FasilitasPage';
import DashboardPage from './HomePage/DashboardPage';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const cookies = new Cookies();

const Admin = (props) => {
  const { container, query, history, mutate } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const logout = () => {
    const cookie = new Cookies();
    cookie.remove('id');
    cookie.remove('name');
    cookie.remove('token', { path: '/' });
    history.replace('/login');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerAdmin = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <Tooltip title="Dashboard">
          <ListItem component={Link} to="/dashboard" button key="Dashboard">
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </Tooltip>
        <Tooltip title="Tahun Ajaran">
          <ListItem
            component={Link}
            to="/tahun_ajaran"
            button
            key="tahunAjaran"
          >
            <ListItemIcon>
              <CalendarToday />
            </ListItemIcon>
            <ListItemText primary="Tahun Ajaran" />
          </ListItem>
        </Tooltip>
        <Tooltip title="Pengguna">
          <ListItem component={Link} to="/pengguna" button key="pengguna">
            <ListItemIcon>
              <PermIdentity />
            </ListItemIcon>
            <ListItemText primary="Pengguna" />
          </ListItem>
        </Tooltip>
        <Tooltip title="Wali">
          <ListItem component={Link} to="/wali" button key="wali">
            <ListItemIcon>
              <PermIdentity />
            </ListItemIcon>
            <ListItemText primary="Wali" />
          </ListItem>
        </Tooltip>
        <Tooltip title="Siswa">
          <ListItem component={Link} to="/siswa" button key="siswa">
            <ListItemIcon>
              <SupervisedUserCircle />
            </ListItemIcon>
            <ListItemText primary="Siswa" />
          </ListItem>
        </Tooltip>
        <List>
          <Tooltip title="Log Out">
            <ListItem button key="Logout" onClick={logout}>
              <ListItemIcon>
                <PowerSettingsNew />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItem>
          </Tooltip>
        </List>
      </List>
      <Divider />
    </div>
  );

  const drawerUser = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <Tooltip title="Registrasi">
          <ListItem component={Link} to="/register" button key="Registrasi">
            <ListItemIcon>
              <PermIdentity />
            </ListItemIcon>
            <ListItemText primary="Registrasi" />
          </ListItem>
        </Tooltip>
        <Tooltip title="Syarat Pendaftaran">
          <ListItem
            component={Link}
            to="/syarat"
            button
            key="Syarat Pendaftaran"
          >
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary="Syarat Pendaftaran" />
          </ListItem>
        </Tooltip>
        <Tooltip title="Visi dan Misi">
          <ListItem component={Link} to="/visi_misi" button key="Visi dan Misi">
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary="Visi dan Misi" />
          </ListItem>
        </Tooltip>
        <Tooltip title="Fasilitas & Ekstrakurikuler">
          <ListItem
            component={Link}
            to="/fasilitas"
            button
            key="Fasilitas & Ekstrakurikuler"
          >
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary="Fasilitas & Ekstrakurikuler" />
          </ListItem>
        </Tooltip>
        <List>
          <Tooltip title="Log Out">
            <ListItem button key="Logout" onClick={logout}>
              <ListItemIcon>
                <PowerSettingsNew />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItem>
          </Tooltip>
        </List>
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Aplikasi Pendaftaran TK Fajar
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {cookies.get('role') === 'ADMIN' ? drawerAdmin : drawerUser}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {cookies.get('role') === 'ADMIN' ? drawerAdmin : drawerUser}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route
            exact
            path="/tahun_ajaran"
            render={(props) => <TahunAjaranPage query={query} {...props} />}
          />
          <Route
            exact
            path="/tahun_ajaran/add"
            render={(props) => (
              <TahunAjaranForm query={query} mutate={mutate} isAdd {...props} />
            )}
          />
          <Route
            exact
            path="/tahun_ajaran/:tid"
            render={(props) => (
              <TahunAjaranForm query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path="/pengguna"
            render={(props) => <PenggunaPage query={query} {...props} />}
          />
          <Route
            exact
            path="/pengguna/add"
            render={(props) => (
              <PenggunaForm query={query} mutate={mutate} isAdd {...props} />
            )}
          />
          <Route
            exact
            path="/pengguna/:uid"
            render={(props) => (
              <PenggunaForm query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path="/wali"
            render={(props) => <WaliPage query={query} {...props} />}
          />
          <Route
            exact
            path="/wali/add"
            render={(props) => (
              <WaliForm query={query} mutate={mutate} isAdd {...props} />
            )}
          />
          <Route
            exact
            path="/wali/:uid"
            render={(props) => (
              <WaliForm query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path="/siswa"
            render={(props) => <SiswaPage query={query} {...props} />}
          />
          <Route
            exact
            path="/siswa/add"
            render={(props) => (
              <RegistrasiPage query={query} mutate={mutate} {...props} />
            )}
          />
          <Route
            exact
            path="/siswa/edit/:nis"
            render={(props) => (
              <RegistrasiPage query={query} mutate={mutate} edit {...props} />
            )}
          />
          <Route
            exact
            path="/register"
            render={(props) => (
              <RegistrasiPage query={query} mutate={mutate} isAdd {...props} />
            )}
          />
          <Route
            exact
            path="/syarat"
            render={(props) => <SyaratPage {...props} />}
          />
          <Route
            exact
            path="/visi_misi"
            render={(props) => <VisiMisiPage {...props} />}
          />
          <Route
            exact
            path="/fasilitas"
            render={(props) => <FasilitasPage {...props} />}
          />
          <Route
            exact
            path="/dashboard"
            render={(props) => <DashboardPage query={query} {...props} />}
          />
          <Route
            exact
            path="/siswa/verif/:id"
            render={(props) => (
              <VerificationSiswa query={query} mutate={mutate} {...props} />
            )}
          />
          <Redirect exact from="/" to="/admin" />
        </Switch>
      </main>
    </div>
  );
};

Admin.propTypes = {
  container: PropTypes.instanceOf(
    typeof Element === 'undefined' ? Object : Element
  ),
};

export default Admin;
