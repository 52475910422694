import { Typography } from '@material-ui/core';
import React from 'react';

const FasilitasPage = () => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        <b>Fasilitas:</b>
      </Typography>
      <Typography variant="h6">1. Ruang kelas</Typography>
      <Typography variant="h6">2. Tempat bermain</Typography>
      <Typography variant="h6" gutterBottom>
        3. Tenaga pelajar yang berpengalaman
      </Typography>
      <Typography variant="h5" gutterBottom>
        <b>Ekstrakurikuler:</b>
      </Typography>
      <Typography variant="h6">1. Melukis</Typography>
      <Typography variant="h6">2. Tari</Typography>
    </div>
  );
};

export default FasilitasPage;
