import React, { useEffect } from 'react';
import { Typography, Container } from '@material-ui/core';
import { VERIFIKASI_PENGGUNA } from '../graphql/Pengguna';

const MyVerification = (props) => {
  const { match, mutate } = props;
  const { uid } = match.params;

  useEffect(() => {
    const verifikasiPengguna = async () => {
      const {
        data: { verifikasiPengguna },
      } = await mutate({
        mutation: VERIFIKASI_PENGGUNA,
        variables: { uid },
        fetchPolicy: 'no-cache',
        onError: (error) => {
          console.log('==> Error execute mutation', error);
        },
      });

      return verifikasiPengguna;
    };

    verifikasiPengguna();
  }, [mutate, uid]);

  return (
    <Container maxWidth="sm">
      <div>
        <img
          alt="logo"
          src="https://tools.atimo.us/images/thanks_email_verification.jpg"
          style={{
            display: 'block',
            margin: '15vh auto 10vh auto',
            width: '100%',
          }}
        />
        <Typography align="center" variant="subtitle2">
          Selamat, status Pengguna Website Pendaftaran TK Fajar Anda telah
          Aktif. Terima kasih sudah mendaftar.
        </Typography>
      </div>
    </Container>
  );
};

export default MyVerification;
