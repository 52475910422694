import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Table,
  TableCell,
  TableBody,
  TableRow,
} from '@material-ui/core/';

const MyModal = (props) => {
  const { open, handleClose, type, mappingRows, data } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">Detail {type}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Table aria-label="simple-table">
              <TableBody>
                {Object.keys(mappingRows).map((x, i) => (
                  <TableRow key={i}>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {typeof mappingRows[x] === 'object'
                        ? mappingRows[x].title
                        : mappingRows[x]}
                    </TableCell>
                    <TableCell>
                      {typeof mappingRows[x] === 'object'
                        ? data[Object.keys(mappingRows)[i]]
                          ? data[Object.keys(mappingRows)[i]][
                              mappingRows[x].row
                            ]
                          : null
                        : data[Object.keys(mappingRows)[i]]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyModal;
