export const setError = (setSnack, error) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0)
    setSnack({
      variant: 'error',
      message: error.graphQLErrors[0].message,
      opened: true,
    });
  if (error.networkError && error.networkError.length > 0)
    setSnack({
      variant: 'error',
      message: error.networkError[0].message,
      opened: true,
    });
};
