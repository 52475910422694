import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import { GET_DASHBOARD } from '../../graphql/Siswa';
import GlobalContext from '../../utils/GlobalContext';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    textAlign: 'center',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard(props) {
  const { query } = props;
  const [dashboard, setDashboard] = useState({});
  const classes = useStyles();
  const { setGlobalLoading } = useContext(GlobalContext);

  useEffect(() => {
    setGlobalLoading(true);

    const getPenggunaByUid = async () => {
      const {
        data: { getDashboard },
      } = await query({
        query: GET_DASHBOARD,
        fetchPolicy: 'no-cache',
      });

      setDashboard(getDashboard);
      setGlobalLoading(false);
    };
    getPenggunaByUid();
  }, [query, setGlobalLoading]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                TOTAL PENDAFTAR
              </Typography>
              <Typography variant="h3" component="p">
                {dashboard.total_pendaftar}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                USIA 4-5 TAHUN
              </Typography>
              <Typography variant="h3" component="p">
                {dashboard.usia_4}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                USIA 5-6 TAHUN
              </Typography>
              <Typography variant="h3" component="p">
                {dashboard.usia_5}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                SISWA LAKI-LAKI
              </Typography>
              <Typography variant="h3" component="p">
                {dashboard.total_jenis_kelamin?.laki_laki}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                SISWA PEREMPUAN
              </Typography>
              <Typography variant="h3" component="p">
                {dashboard.total_jenis_kelamin?.perempuan}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
