import React, { useState, useEffect, useContext } from 'react';
import { Chip } from '@material-ui/core';
import MyTable from '../MyTable';
import MyModal from '../MyModal';
import { TAHUN_AJARAN } from '../../graphql/TahunAjaran';
import GlobalContext from '../../utils/GlobalContext';

const TahunAjaranPage = (props) => {
  const { query, history } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [tahunAjaran, setTahunAjaran] = useState([]);
  const [tempData, setTempData] = useState({});
  const [open, setOpen] = React.useState(false);
  const COLUMNS = [
    {
      title: 'Nomor',
      render: (rowData) => `${rowData.tableData.id + 1}.`,
    },
    { title: 'Kode', field: 'tid', hidden: true },
    { title: 'Periode Awal', field: 'periode_awal' },
    { title: 'Periode Akhir', field: 'periode_akhir' },
    {
      title: 'Status',
      field: 'active',
      render: (rowData) => (
        <Chip
          label={rowData.aktif ? 'Aktif' : 'Tidak Aktif'}
          color={rowData.aktif ? 'primary' : 'secondary'}
        />
      ),
    },
    { title: 'Semester', field: 'semester' },
    {
      title: 'Tanggal Dibuat',
      field: 'created_at',
      type: 'datetime',
    },
  ];
  const TITLE = 'Tahun Ajaran';

  useEffect(() => {
    setGlobalLoading(true);
    const getTahunAjaran = async () => {
      const {
        data: { tahunAjaran },
      } = await query({
        query: TAHUN_AJARAN,
        fetchPolicy: 'no-cache',
      });
      setTahunAjaran(tahunAjaran);
    };

    getTahunAjaran();
    setGlobalLoading(false);
  }, [query, setGlobalLoading]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <MyTable
        history={history}
        columns={COLUMNS}
        data={tahunAjaran}
        title={TITLE}
        addUrl="/tahun_ajaran/add"
        editUrl="/tahun_ajaran"
        keyColumn="tid"
        setOpen={setOpen}
        setTempData={setTempData}
      />
      <MyModal
        open={open}
        handleClose={handleClose}
        data={tempData}
        type="Tahun Ajaran"
        mappingRows={{
          id: 'ID Tahun Ajaran',
          periode_awal: 'Periode Awal',
          periode_akhir: 'Periode Akhir',
          created_at: 'Tanggal Dibuat',
        }}
      />
    </React.Fragment>
  );
};

export default TahunAjaranPage;
