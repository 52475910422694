import React from 'react';
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Formik } from 'formik';

import style from '../../utils/style';

const FormE = (props) => {
  const classes = style();
  const {
    formValues,
    _handleSubmit,
    isLastStep,
    activeStep,
    handleBack,
  } = props;

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Formik
          enableReinitialize
          initialValues={{
            imunisasi: formValues ? formValues.imunisasi : '',
            histori_penyakit: formValues ? formValues.histori_penyakit : '',
          }}
        >
          {({ values, handleChange }) => (
            <form noValidate>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      autoFocus
                      margin="normal"
                      fullWidth
                      id="imunisasi"
                      label="Imunisasi / Suntikan yang Pernah Didapat"
                      name="imunisasi"
                      onChange={handleChange}
                      value={values.imunisasi}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="histori_penyakit"
                      label="Penyakit Berat yang Pernah Diderita"
                      name="histori_penyakit"
                      onChange={handleChange}
                      value={values.histori_penyakit}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <div>
                <br />
                {activeStep !== 0 && (
                  <Button
                    onClick={() => {
                      handleBack(values);
                    }}
                    className={classes.button}
                  >
                    Kembali
                  </Button>
                )}
                &emsp;
                {isLastStep && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      _handleSubmit(values);
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Simpan
                  </Button>
                )}
              </div>
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default FormE;
