import React, { useContext, useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CREATE_PENGGUNA, PENGGUNA_BY_EMAIL } from '../graphql/Pengguna';
import GlobalContext from '../utils/GlobalContext';
import { setError } from '../utils';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();
  const { query, mutate, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [email, setEmail] = useState('');
  const [namaLengkap, setNamaLengkap] = useState('');
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Halaman Pendaftaran TK Fajar
        </Typography>
        <Formik
          enableReinitialize
          initialValues={{
            email,
            nama_lengkap: namaLengkap,
          }}
          validationSchema={Yup.object().shape({
            nama_lengkap: Yup.string().required('Harus diisi'),
            email: Yup.string()
              .email('Email tidak valid')
              .required('Harus diisi'),
          })}
          onSubmit={async ({ email, nama_lengkap }, { resetForm }) => {
            const {
              data: { findPenggunaByEmail },
            } = await query({
              query: PENGGUNA_BY_EMAIL,
              variables: {
                email,
              },
              fetchPolicy: 'no-cache',
            });

            if (findPenggunaByEmail) {
              setSnack({
                variant: 'error',
                message: `Email ${email} sudah terdaftar di sistem.`,
                opened: true,
              });

              return false;
            }

            try {
              setGlobalLoading(true);
              const {
                data: { createPengguna },
              } = await mutate({
                mutation: CREATE_PENGGUNA,
                variables: {
                  data: {
                    nama_lengkap,
                    email,
                    password: new Date().getTime().toString(),
                    role: 'USER',
                  },
                },
                fetchPolicy: 'no-cache',
              });

              setSnack({
                variant: 'success',
                message: `Pengguna ${createPengguna.email} Berhasil ditambah! Silahkan cek inbox email yang sudah terdaftar.`,
                opened: true,
              });
              setGlobalLoading(false);
              resetForm();
              history.replace('/login');
            } catch (error) {
              setGlobalLoading(false);
              setError(setSnack, error);
            }
          }}
        >
          {({ errors, touched, handleSubmit, values, handleChange }) => (
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    autoComplete="fname"
                    name="nama_lengkap"
                    variant="outlined"
                    required
                    fullWidth
                    id="nama_lengkap"
                    label="Nama Lengkap"
                    onChange={handleChange}
                    autoFocus
                  />
                  {errors.nama_lengkap && touched.nama_lengkap && (
                    <div style={{ color: 'red' }}>{errors.nama_lengkap}</div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    autoComplete="email"
                  />
                  {errors.email && touched.email && (
                    <div style={{ color: 'red' }}>{errors.email}</div>
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Sign Up
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Sudah punya akun? Masuk
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
