import React from 'react';
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Formik } from 'formik';

import style from '../../utils/style';

const FormD = (props) => {
  const classes = style();
  const { formValues, handleNext, activeStep, handleBack } = props;

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Formik
          enableReinitialize
          initialValues={{
            status_tinggal_anak: formValues
              ? formValues.status_tinggal_anak
              : '',
            jarak_rumah_ke_sekolah: formValues
              ? formValues.jarak_rumah_ke_sekolah
              : 0,
            transportasi_ke_sekolah: formValues
              ? formValues.transportasi_ke_sekolah
              : '',
            bakat_anak: formValues ? formValues.bakat_anak : '',
            golongan_darah_anak: formValues
              ? formValues.golongan_darah_anak
              : '',
          }}
        >
          {({
            errors,
            touched,
            setTouched,
            validateForm,
            values,
            handleChange,
          }) => (
            <form noValidate>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="status_tinggal_anak">
                        Status Tinggal
                      </InputLabel>
                      <Select
                        autoFocus
                        margin="normal"
                        fullWidth
                        id="status_tinggal_anak"
                        label="status_tinggal_anak"
                        name="status_tinggal_anak"
                        onChange={handleChange}
                        value={values.status_tinggal_anak}
                      >
                        <MenuItem value="" disabled>
                          --- Pilih ---
                        </MenuItem>
                        <MenuItem value="Tinggal dengan Orang Tua">
                          Tinggal dengan Orang Tua
                        </MenuItem>
                        <MenuItem value="Menumpang">Menumpang</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="jarak_rumah_ke_sekolah"
                      label="Jarak Dari Tempat Tinggal Ke Sekolah"
                      name="jarak_rumah_ke_sekolah"
                      onChange={handleChange}
                      value={values.jarak_rumah_ke_sekolah}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="transportasi_ke_sekolah">
                        Transportasi Ke Sekolah
                      </InputLabel>
                      <Select
                        margin="normal"
                        fullWidth
                        id="transportasi_ke_sekolah"
                        label="transportasi_ke_sekolah"
                        name="transportasi_ke_sekolah"
                        onChange={handleChange}
                        value={values.transportasi_ke_sekolah}
                      >
                        <MenuItem value="" disabled>
                          --- Pilih ---
                        </MenuItem>
                        <MenuItem value="Dengan Kendaraan">
                          Dengan Kendaraan
                        </MenuItem>
                        <MenuItem value="Jalan Kaki">Jalan Kaki</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="bakat_anak"
                      label="Bakat / Minat Anak yang Menonjol"
                      name="bakat_anak"
                      onChange={handleChange}
                      value={values.bakat_anak}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="golongan_darah_anak">
                        Golongan Darah
                      </InputLabel>
                      <Select
                        margin="normal"
                        fullWidth
                        id="golongan_darah_anak"
                        label="Golongan Darah"
                        name="golongan_darah_anak"
                        onChange={handleChange}
                        value={values.golongan_darah_anak}
                      >
                        <MenuItem value="" disabled>
                          --- Pilih ---
                        </MenuItem>
                        <MenuItem value="A">A</MenuItem>
                        <MenuItem value="B">B</MenuItem>
                        <MenuItem value="O">O</MenuItem>
                        <MenuItem value="AB">AB</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <div>
                <br />
                {activeStep !== 0 && (
                  <Button
                    onClick={() => {
                      handleBack(values);
                    }}
                    className={classes.button}
                  >
                    Kembali
                  </Button>
                )}
                &emsp;
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    validateForm().then((errors) => {
                      if (
                        Object.entries(errors).length === 0 &&
                        errors.constructor === Object
                      ) {
                        handleNext(values);
                      } else {
                        setTouched(errors);
                      }
                    })
                  }
                >
                  Lanjut
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default FormD;
