import { gql } from '@apollo/client';

export const SISWA_HOME = gql`
  query siswa {
    siswa {
      id
      nis
      nama_lengkap
      nama_ayah
      aktif
      created_at
    }
  }
`;

export const REGISTRASI_SISWA = gql`
  mutation registrasiSiswa($data: InSiswa!, $uid: String!) {
    registrasiSiswa(data: $data, uid: $uid) {
      id
      nis
      nama_lengkap
    }
  }
`;

export const UPDATE_SISWA = gql`
  mutation updateSiswa($data: InSiswa!, $nis: String!) {
    updateSiswa(data: $data, nis: $nis) {
      id
      nis
      nama_lengkap
    }
  }
`;

export const CHECK_SISWA = gql`
  query checkDataRegister($uid: String!) {
    checkDataRegister(uid: $uid) {
      id
      nis
      nama_lengkap
      nama_ayah
      aktif
      created_at
    }
  }
`;

export const GET_DASHBOARD = gql`
  query getDashboard {
    getDashboard {
      total_pendaftar
      usia_4
      usia_5
      total_jenis_kelamin {
        laki_laki
        perempuan
      }
    }
  }
`;

export const VERIFICATION_SISWA = gql`
  mutation verificationSiswa(
    $id: ID!
    $nis: String!
    $keterangan_pembayaran: String
    $status_pembayaran: Boolean
  ) {
    verificationSiswa(
      id: $id
      nis: $nis
      keterangan_pembayaran: $keterangan_pembayaran
      status_pembayaran: $status_pembayaran
    ) {
      id
      nis
      nama_lengkap
      nama_ayah
      created_at
    }
  }
`;

export const SISWA_BY_ID = gql`
  query siswaById($id: ID!) {
    siswaById(id: $id) {
      id
      nis
      nama_lengkap
      nama_ayah
      aktif
      keterangan_pembayaran
      status_pembayaran
      created_at
    }
  }
`;

export const SISWA_BY_NIS = gql`
  query siswaByNis($nis: String!) {
    siswaByNis(nis: $nis) {
      pengguna_id
      nis
      ksk
      nama_lengkap
      nama_panggilan
      jenis_kelamin
      tempat_lahir
      tanggal_lahir
      alamat_anak
      no_telepon_anak
      anak_ke
      jumlah_saudara
      saudara_kandung
      saudara_tiri
      saudara_angkat
      status_anak
      bahasa_sehari
      warga_negara
      agama
      kelainan_jasmani
      asal_sekolah
      nama_ayah
      tempat_lahir_ayah
      tanggal_lahir_ayah
      agama_ayah
      pendidikan_tertinggi_ayah
      pekerjaan_jabatan_ayah
      penghasilan_ayah
      warga_negara_ayah
      alamat_ayah
      telepon_rumah_ayah
      telepon_kantor_ayah
      nama_wali
      tempat_lahir_wali
      tanggal_lahir_wali
      agama_wali
      pendidikan_tertinggi_wali
      pekerjaan_jabatan_wali
      penghasilan_wali
      warga_negara_wali
      alamat_wali
      telepon_rumah_wali
      telepon_kantor_wali
      status_tinggal_anak
      jarak_rumah_ke_sekolah
      transportasi_ke_sekolah
      bakat_anak
      golongan_darah_anak
      imunisasi
      histori_penyakit
    }
  }
`;

export const SISWA = gql`
  query siswa {
    siswa {
      pengguna_id
      nis
      ksk
      nama_lengkap
      nama_panggilan
      jenis_kelamin
      tempat_lahir
      tanggal_lahir
      alamat_anak
      no_telepon_anak
      anak_ke
      jumlah_saudara
      saudara_kandung
      saudara_tiri
      saudara_angkat
      status_anak
      bahasa_sehari
      warga_negara
      agama
      kelainan_jasmani
      asal_sekolah
      nama_ayah
      tempat_lahir_ayah
      tanggal_lahir_ayah
      agama_ayah
      pendidikan_tertinggi_ayah
      pekerjaan_jabatan_ayah
      penghasilan_ayah
      warga_negara_ayah
      alamat_ayah
      telepon_rumah_ayah
      telepon_kantor_ayah
      nama_wali
      tempat_lahir_wali
      tanggal_lahir_wali
      agama_wali
      pendidikan_tertinggi_wali
      pekerjaan_jabatan_wali
      penghasilan_wali
      warga_negara_wali
      alamat_wali
      telepon_rumah_wali
      telepon_kantor_wali
      status_tinggal_anak
      jarak_rumah_ke_sekolah
      transportasi_ke_sekolah
      bakat_anak
      golongan_darah_anak
      imunisasi
      histori_penyakit
    }
  }
`;
