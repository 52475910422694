import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  Paper,
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import GlobalContext from '../../utils/GlobalContext';
import style from '../../utils/style';
import { setError } from '../../utils';
import { VERIFICATION_SISWA, SISWA_BY_ID } from '../../graphql/Siswa';
const VerificationSiswa = (props) => {
  const { history, query, mutate, match } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [siswa, setSiswa] = useState({});
  const classes = style();

  useEffect(() => {
    if (match.params.id) {
      setGlobalLoading(true);

      const getPenggunaByUid = async () => {
        const {
          data: { siswaById },
        } = await query({
          query: SISWA_BY_ID,
          variables: {
            id: match.params.id,
          },
          fetchPolicy: 'no-cache',
        });
        console.log(siswaById);
        setSiswa(siswaById);
        setGlobalLoading(false);
      };
      getPenggunaByUid();
    }
  }, [match.params.id, query, setGlobalLoading]);

  return (
    <div>
      <Grid container spacing={0} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Formik
            enableReinitialize
            initialValues={{
              nis: '',
              keterangan_pembayaran: siswa ? siswa.keterangan_pembayaran : '',
              status_pembayaran: siswa ? siswa.status_pembayaran : false,
            }}
            validationSchema={Yup.object().shape({
              nis: Yup.string().when('status_pembayaran', {
                is: true,
                then: Yup.string().required('Harus diisi'),
              }),
            })}
            onSubmit={async (
              { nis, status_pembayaran, keterangan_pembayaran },
              { resetForm }
            ) => {
              try {
                setGlobalLoading(true);
                console.log(status_pembayaran, 'status_pembayaran');
                const {
                  data: { verificationSiswa },
                } = await mutate({
                  mutation: VERIFICATION_SISWA,
                  variables: {
                    id: match.params.id,
                    nis,
                    status_pembayaran,
                    keterangan_pembayaran,
                  },
                  fetchPolicy: 'no-cache',
                });
                setSnack({
                  variant: 'success',
                  message: `NIS ${verificationSiswa.nama_lengkap} berhasil ditambah!`,
                  opened: true,
                });
                setGlobalLoading(false);
                resetForm();
                history.replace('/siswa');
              } catch (error) {
                setGlobalLoading(false);
                setError(setSnack, error);
              }
            }}
          >
            {({ errors, touched, handleSubmit, values, handleChange }) => (
              <form noValidate>
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="nama_lengkap"
                        label="Nama Lengkap"
                        name="nama_lengkap"
                        value={siswa.nama_lengkap}
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        autoFocus
                        margin="normal"
                        fullWidth
                        id="nis"
                        label="Nomor Induk Siswa"
                        name="nis"
                        onChange={handleChange}
                        value={values.nis}
                      />
                      {errors.nis && touched.nis && (
                        <div style={{ color: 'red' }}>{errors.nis}</div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Status Pembayaran
                        </FormLabel>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={values.status_pembayaran}
                                onChange={handleChange}
                                margin="normal"
                                fullWidth
                                id="status_pembayaran"
                                label="Nomor Induk Siswa"
                                name="status_pembayaran"
                                color="primary"
                              />
                            }
                            label="Lunas"
                            labelPlacement="lunas"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="keterangan_pembayaran"
                        label="Keterangan Pembayaran"
                        name="keterangan_pembayaran"
                        onChange={handleChange}
                        value={values.keterangan_pembayaran}
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="baseline"
                    >
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{
                          marginRight: 10,
                        }}
                      >
                        Simpan
                      </Button>
                      <Button
                        onClick={() => history.replace('/siswa')}
                        color="primary"
                      >
                        Kembali
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default VerificationSiswa;
