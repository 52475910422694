import React from 'react';
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import moment from 'moment';
import { Formik } from 'formik';

import style from '../../utils/style';

const FormB = (props) => {
  const classes = style();
  const { formValues, handleNext, activeStep, handleBack } = props;

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Formik
          enableReinitialize
          initialValues={{
            nama_ayah: formValues ? formValues.nama_ayah : '',
            tempat_lahir_ayah: formValues ? formValues.tempat_lahir_ayah : '',
            tanggal_lahir_ayah: formValues
              ? moment(formValues.tanggal_lahir_ayah).format('YYYY-MM-DD')
              : '',
            agama_ayah: formValues ? formValues.agama_ayah : '',
            pendidikan_tertinggi_ayah: formValues
              ? formValues.pendidikan_tertinggi_ayah
              : '',
            pekerjaan_jabatan_ayah: formValues
              ? formValues.pekerjaan_jabatan_ayah
              : '',
            penghasilan_ayah: formValues ? formValues.penghasilan_ayah : 0,
            warga_negara_ayah: formValues ? formValues.warga_negara_ayah : '',
            alamat_ayah: formValues ? formValues.alamat_ayah : '',
            telepon_rumah_ayah: formValues ? formValues.telepon_rumah_ayah : '',
            telepon_kantor_ayah: formValues
              ? formValues.telepon_kantor_ayah
              : '',
          }}
        >
          {({
            errors,
            touched,
            setTouched,
            validateForm,
            values,
            handleChange,
          }) => (
            <form noValidate>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      autoFocus
                      margin="normal"
                      fullWidth
                      id="nama_ayah"
                      label="Nama Ayah"
                      name="nama_ayah"
                      onChange={handleChange}
                      value={values.nama_ayah}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="tempat_lahir_ayah"
                      label="Tempat Lahir Ayah"
                      name="tempat_lahir_ayah"
                      onChange={handleChange}
                      value={values.tempat_lahir_ayah}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="tanggal_lahir_ayah"
                      label="Tanggal Lahir Ayah"
                      name="tanggal_lahir_ayah"
                      onChange={handleChange}
                      value={values.tanggal_lahir_ayah}
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="agama_ayah">Agama</InputLabel>
                      <Select
                        margin="normal"
                        fullWidth
                        id="agama_ayah"
                        label="agama_ayah"
                        name="agama_ayah"
                        onChange={handleChange}
                        value={values.agama_ayah}
                      >
                        <MenuItem value="" disabled>
                          --- Pilih ---
                        </MenuItem>
                        <MenuItem value="Islam">Islam</MenuItem>
                        <MenuItem value="Kristen">Kristen</MenuItem>
                        <MenuItem value="Hindu">Hindu</MenuItem>
                        <MenuItem value="Buddha">Buddha</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="pekerjaan_jabatan_ayah"
                      label="Pekerjaan / Jabatan"
                      name="pekerjaan_jabatan_ayah"
                      onChange={handleChange}
                      value={values.pekerjaan_jabatan_ayah}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="penghasilan_ayah"
                      label="Penghasilan Tiap Bulan"
                      name="penghasilan_ayah"
                      onChange={handleChange}
                      value={values.penghasilan_ayah}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="warga_negara_ayah"
                      label="Kewarganegaraan"
                      name="warga_negara_ayah"
                      onChange={handleChange}
                      value={values.warga_negara_ayah}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="telepon_rumah_ayah"
                      label="Telepon Rumah"
                      name="telepon_rumah_ayah"
                      onChange={handleChange}
                      value={values.telepon_rumah_ayah}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="telepon_kantor_ayah"
                      label="Telepon Kantor"
                      name="telepon_kantor_ayah"
                      onChange={handleChange}
                      value={values.telepon_kantor_ayah}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="alamat_ayah"
                      label="Alamat"
                      name="alamat_ayah"
                      onChange={handleChange}
                      value={values.alamat_ayah}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <div>
                <br />
                {activeStep !== 0 && (
                  <Button
                    onClick={() => {
                      handleBack(values);
                    }}
                    className={classes.button}
                  >
                    Kembali
                  </Button>
                )}
                &emsp;
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    validateForm().then((errors) => {
                      if (
                        Object.entries(errors).length === 0 &&
                        errors.constructor === Object
                      ) {
                        handleNext(values);
                      } else {
                        setTouched(errors);
                      }
                    })
                  }
                >
                  Lanjut
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default FormB;
