import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Informasi Pendaftaran TK Fajar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              variant="h6"
              style={{ textAlign: 'justify', color: 'black' }}
            >
              Ketentuan pendaftaran sebelum login pendaftaran online
            </Typography>
            <br />
            <div style={{ textAlign: 'justify', color: 'black' }}>
              <Typography variant="subtitle1">
                1. Formulir pendaftaran online ini tidak diperbolehkan untuk
                penggunaan uji coba, penggunaan yang menyalahgunakan aturan
                ataupun bertentangan dengan hukum dapat dikenakan sanksi atau
                terjerat pasal perundangan.
              </Typography>
              <br />
              <Typography variant="subtitle1">
                2. Data yang diisikan pada formulir pendaftaran online ini harus
                valid (data yang sebenarnya) dan bisa dipertanggungjawabkan.
                Apabila nantinya ditemukan ketidaksesuaian dengan data asli,
                proses pendaftaran akan dibatalkan.
              </Typography>
              <br />
              <Typography variant="subtitle1">
                3. Formulir pendaftaran ini berada dalam lingkup administrasi TK
                Fajar.
              </Typography>
              <br />
              <Typography variant="subtitle1">
                4.Uang pendaftaran: Siswa Baru <b>Rp. 1.000.000</b> (sudah
                termasuk buku kegiatan, kegiatan sekolah selama 1 tahun
                pelajaran serta seragam sekolah 2 stel)
              </Typography>
              <br />
              <Typography variant="subtitle1">
                5. Jika anda sudah mendaftar akan mendapat nomor induk sekolah
                untuk siswa, nama user dan password
              </Typography>
              <br />
              <Typography variant="subtitle1">
                6. Uang pendaftaran dapat ditransfer ke rekening TK Fajar:{' '}
                <b>0372103540</b>. Jika sudah berhasil transfer mohon dikirim
                foto atau screenshot ke WA <b>082245405777</b>.
              </Typography>
              <br />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
