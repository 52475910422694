import jsPDF from 'jspdf';
import moment from 'moment';

const generatePDF = async (data, query, { SISWA_BY_NIS }) => {
  /** DO QUERY HERE **/
  const {
    data: { siswaByNis },
  } = await query({
    query: SISWA_BY_NIS,
    variables: {
      nis: data.nis,
    },
    fetchPolicy: 'no-cache',
  });
  const siswa = siswaByNis;

  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'portrait' });
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const HEADER = 'PEMERINTAH KOTA SURABAYA \n DINAS PENDIDIKAN \n TK FAJAR';
  const ADDRESS = 'Jl. Krembangan Baru VI/19 - Surabaya';
  const SUB_HEADER = 'LAPORAN DATA DIRI MURID';
  debugger;
  // HEADER
  doc.setFontSize(14);
  doc.setFont('times', 'bold');
  doc.text(HEADER, pageWidth / 2, 8, 'center');
  doc.setFont('times', 'normal');
  doc.setFontSize(10);
  doc.text(ADDRESS, pageWidth / 2, 24, 'center');
  doc.setLineWidth(1);
  doc.line(10, 28, pageWidth - 10, 28);
  doc.setLineWidth(1.5);
  doc.line(10, 30, pageWidth - 10, 30);

  // SUB HEADER
  doc.setFontSize(12);
  doc.setFont('times', 'bold');
  doc.text(SUB_HEADER, pageWidth / 2, 38, 'center');

  let initY = 46;
  const margin = 6;

  // SECTION I
  doc.setFontSize(12);
  doc.setFont('times', 'bold');
  doc.text('I. Identitas Murid', 10, 46);
  doc.setFont('times', 'normal');

  doc.text('1. Nama Lengkap', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.nama_lengkap, 60, initY + margin);
  initY += margin;
  doc.text('1.1 Nama Panggilan', 16, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.nama_panggilan, 60, initY + margin);
  initY += margin;
  doc.text('2. Jenis Kelamin', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.jenis_kelamin, 60, initY + margin);
  initY += margin;
  doc.text('3. Tempat, Tanggal Lahir', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(
    `${siswa.tempat_lahir}, ${moment(siswa.tanggal_lahir).format(
      'DD - MM - YYYY'
    )}`,
    60,
    initY + margin
  );
  initY += margin;
  doc.text('4. Alamat dan No. Telp', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(
    `${siswa.alamat_anak}, ${siswa.no_telepon_anak}`,
    60,
    initY + margin
  );
  initY += margin;
  doc.text('5. Anak Ke Berapa', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(
    `${siswa.alamat_anak}, ${siswa.no_telepon_anak}`,
    60,
    initY + margin
  );
  initY += margin;
  doc.text('6. Jumlah Saudara', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.jumlah_saudara.toString(), 60, initY + margin);
  initY += margin;
  doc.text('6.1 Saudara Kandung', 16, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.saudara_kandung.toString(), 60, initY + margin);
  initY += margin;
  doc.text('6.2 Saudara Tiri', 16, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.saudara_tiri.toString(), 60, initY + margin);
  initY += margin;
  doc.text('6.3 Saudara angkat', 16, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.saudara_angkat.toString(), 60, initY + margin);
  initY += margin;
  doc.text('7. Status Anak', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.status_anak, 60, initY + margin);
  initY += margin;
  doc.text('8. Bahasa Sehari-hari', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.bahasa_sehari, 60, initY + margin);
  initY += margin;
  doc.text('9. Warga Negara', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.warga_negara, 60, initY + margin);
  initY += margin;
  doc.text('10. Agama', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.agama, 60, initY + margin);
  initY += margin;
  doc.text('11. Kelainan Jasmani', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.kelainan_jasmani, 60, initY + margin);
  initY += margin;
  doc.text('12. Asal Sekolah', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.asal_sekolah, 60, initY + margin);

  // SECTION II
  initY += margin;
  doc.setFontSize(12);
  doc.setFont('times', 'bold');
  doc.text('II. Identitas Orang Tua', 10, initY + margin);
  doc.setFont('times', 'normal');
  initY += margin;
  doc.text('1. Nama Ayah', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.nama_ayah, 60, initY + margin);
  initY += margin;
  doc.text('2. Tempat, Tanggal Lahir', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(
    `${siswa.tempat_lahir_ayah} ${moment(siswa.tanggal_lahir_ayah).format(
      'DD - MM - YYYY'
    )}`,
    60,
    initY + margin
  );
  initY += margin;
  doc.text('3. Agama', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.agama_ayah, 60, initY + margin);
  initY += margin;
  doc.text('4. Pendidikan Tertinggi', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.pendidikan_tertinggi_ayah || '-', 60, initY + margin);
  initY += margin;
  doc.text('5. Pekerjaan / Jabatan', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.pekerjaan_jabatan_ayah || '-', 60, initY + margin);
  initY += margin;
  doc.text('6. Penghasilan Tiap Bulan', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(
    `Rp. ${siswa.penghasilan_ayah.toString()}` || '-',
    60,
    initY + margin
  );
  initY += margin;
  doc.text('7. Warga Negara', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.warga_negara_ayah || '-', 60, initY + margin);
  initY += margin;
  doc.text('8. Alamat', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.alamat_ayah || '-', 60, initY + margin);
  initY += margin;
  doc.text('8.1 Telp. Rumah', 16, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.telepon_rumah_ayah || '-', 60, initY + margin);
  initY += margin;
  doc.text('8.2 Telp. Kantor', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.telepon_kantor_ayah || '-', 60, initY + margin);

  // SECTION III
  initY += margin;
  doc.setFontSize(12);
  doc.setFont('times', 'bold');
  doc.text('III. Identitas Wali', 10, initY + margin);
  doc.setFont('times', 'normal');
  initY += margin;
  doc.text('1. Nama Wali Murid', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.nama_wali || '-', 60, initY + margin);
  initY += margin;
  doc.text('2. Tempat, Tanggal Lahir', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(
    siswa.tempat_lahir_wali && siswa.tanggal_lahir_wali
      ? `${siswa.tempat_lahir_wali || '-'} ${moment(
          siswa.tanggal_lahir_wali
        ).format('DD - MM - YYYY')}`
      : '-',
    60,
    initY + margin
  );
  initY += margin;
  doc.text('3. Agama', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.agama_wali || '-', 60, initY + margin);
  initY += margin;
  doc.text('4. Pendidikan Tertinggi', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.pendidikan_tertinggi_wali || '-', 60, initY + margin);
  initY += margin;
  doc.text('5. Pekerjaan / Jabatan', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.pekerjaan_jabatan_wali || '-', 60, initY + margin);
  initY += margin;
  doc.text('6. Penghasilan Tiap Bulan', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(
    siswa.penghasilan_wali ? `Rp ${siswa.penghasilan_wali.toString()}` : '-',
    60,
    initY + margin
  );
  initY += margin;
  doc.text('7. Warga Negara', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.warga_negara_wali || '-', 60, initY + margin);
  initY += margin;
  doc.text('8. Alamat', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.alamat_wali || '-', 60, initY + margin);
  initY += margin;
  doc.text('8.1 Telp. Rumah', 16, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.telepon_rumah_wali || '-', 60, initY + margin);
  initY += margin;
  doc.text('8.2 Telp. Kantor', 16, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.telepon_kantor_wali || '-', 60, initY + margin);

  // SECTION 4
  initY += margin;
  doc.setFontSize(12);
  doc.setFont('times', 'bold');
  doc.text('IV. Keterangan Lain Tentang Anak', 10, initY + margin);
  doc.setFont('times', 'normal');

  initY += margin;
  doc.text('1. Status Tinggal', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.status_tinggal_anak || '-', 60, initY + margin);
  initY += margin;
  doc.text('2. Jarak Ke Sekolah', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(
    siswa.jarak_rumah_ke_sekolah
      ? siswa.jarak_rumah_ke_sekolah.toString()
      : '-',
    60,
    initY + margin
  );
  doc.addPage({ putOnlyUsedFonts: true, orientation: 'portrait' });
  initY = 0;
  doc.text('3. Transport Ke Sekolah', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.transportasi_ke_sekolah || '-', 60, initY + margin);
  initY += margin;
  doc.text('4. Bakat', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.bakat_anak || '-', 60, initY + margin);
  initY += margin;
  doc.text('5. Golongan Darah', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.golongan_darah_anak || '-', 60, initY + margin);

  initY += margin;
  doc.setFontSize(12);
  doc.setFont('times', 'bold');
  doc.text('V. Imunisasi Sebelum Masuk Ke Sekolah', 10, initY + margin);
  doc.setFont('times', 'normal');
  initY += margin;
  doc.text('1. Imunisasi', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.imunisasi || '-', 60, initY + margin);
  initY += margin;
  doc.text('2. Histori Penyakit', 13, initY + margin);
  doc.text(':', 58, initY + margin);
  doc.text(siswa.histori_penyakit || '-', 60, initY + margin);

  // TTD
  initY += margin;
  doc.text('Surabaya, ..............................', 135, initY + margin);
  initY += margin;
  doc.text('Bunda Emi', 135, initY + 25);
  doc.save(`DATA_MURID_${data.nis}.pdf`);

  // FOR DEBUG, OPEN PDF NEW TAB
  // doc.output('dataurlnewwindow');
};

export default generatePDF;
