import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const generatePDF = async (query, schema) => {
  const headTable = () => {
    return [
      {
        id: 'No.',
        nis: 'Nomor Induk Siswa (NIS)',
        nama_lengkap: 'Nama Lengkap',
        jenis_kelamin: 'Jenis Kelamin',
        nama_wali: 'Nama Wali',
        no_telepon_anak: 'No. Telpon Anak',
      },
    ];
  };

  /** DO QUERY HERE **/
  const {
    data: { siswa },
  } = await query({
    query: schema,
    fetchPolicy: 'no-cache',
  });

  const rowsTable = () => {
    const body = siswa.map((x, i) => ({
      id: `${i + 1}.`,
      nis: x.nis,
      nama_lengkap: x.nama_lengkap,
      jenis_kelamin: x.jenis_kelamin,
      nama_wali: x.nama_ayah || x.nama_wali,
      no_telepon_anak: x.no_telepon_anak,
    }));

    return body;
  };
  debugger;
  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'portrait' });
  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const HEADER = 'PEMERINTAH KOTA SURABAYA \n DINAS PENDIDIKAN \n TK FAJAR';
  const ADDRESS = 'Jl. Krembangan Baru VI/19 - Surabaya';
  const SUB_HEADER = 'LAPORAN DATA DIRI MURID';
  debugger;
  // HEADER
  doc.setFontSize(14);
  doc.setFont('times', 'bold');
  doc.text(HEADER, pageWidth / 2, 8, 'center');
  doc.setFont('times', 'normal');
  doc.setFontSize(10);
  doc.text(ADDRESS, pageWidth / 2, 24, 'center');
  doc.setLineWidth(1);
  doc.line(10, 28, pageWidth - 10, 28);
  doc.setLineWidth(1.5);
  doc.line(10, 30, pageWidth - 10, 30);

  // SUB HEADER
  doc.setFontSize(12);
  doc.setFont('times', 'bold');
  doc.text(SUB_HEADER, pageWidth / 2, 38, 'center');

  autoTable(doc, {
    startY: 50,
    theme: 'striped',
    headStyles: { fillColor: [0, 0, 0] },
    head: headTable(),
    body: rowsTable(),
  });

  doc.save(`DATA_SEMUA_MURID.pdf`);

  // FOR DEBUG, OPEN PDF NEW TAB
  // doc.output('dataurlnewwindow');
};

export default generatePDF;
