import React from 'react';
import MaterialTable from 'material-table';

const MyTable = (props) => {
  const {
    columns,
    data,
    title,
    addUrl,
    editUrl,
    history,
    viewOnly,
    setOpen,
    setTempData,
    keyColumn,
    query,
    schema,
    pdf,
  } = props;
  debugger;
  const addButton = {
    icon: 'add',
    tooltip: `Tambah ${title}`,
    isFreeAction: true,
    onClick: () => {
      history.replace(addUrl);
    },
  };

  const editButton = {
    icon: 'edit',
    tooltip: `Edit ${title}`,
    onClick: (_, rowData) => {
      history.replace(`${editUrl}/${rowData[keyColumn]}`);
    },
  };

  const printButton = {
    icon: 'print',
    tooltip: 'Cetak PDF',
    onClick: (_, rowData) => {
      pdf(rowData, query, { SISWA_BY_NIS: schema });
    },
  };

  const openModal = {
    icon: 'chevron_right',
    tooltip: `Detail ${title}`,
    onClick: (_, rowData) => {
      setOpen(true);
      setTempData(rowData);
    },
  };

  const buttonVerification = (rowData) => {
    return {
      icon: 'edit',
      tooltip: 'Verifikasi Siswa',
      onClick: (_, rowData) => {
        history.replace(`siswa/verif/${rowData.id}`);
      },
      hidden: rowData.aktif,
    };
  };

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={data}
        title={title}
        actions={[
          addUrl && addButton,
          !viewOnly && openModal,
          editUrl && editButton,
          !viewOnly ? (rowData) => buttonVerification(rowData) : null,
          pdf && printButton,
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} dari {count}',
            labelRowsSelect: 'Baris Data',
            firstAriaLabel: 'Halaman Pertama',
            firstTooltip: 'Halaman Pertama',
            previousAriaLabel: 'Halaman Sebelumnya',
            previousTooltip: 'Halaman Sebelumnya',
            nextAriaLabel: 'Halaman Selanjutnya',
            nextTooltip: 'Halaman Selanjutnya',
            lastAriaLabel: 'Halaman Terakhir',
            lastTooltip: 'Halaman Terakhir',
          },
          header: {
            actions: 'Aksi',
          },
          body: {
            emptyDataSourceMessage: 'Data tidak ditemukan',
            filterRow: {
              filterTooltip: 'Saring',
            },
          },
          toolbar: {
            searchTooltip: 'Cari',
            searchPlaceholder: 'Cari',
          },
        }}
      />
    </div>
  );
};

export default MyTable;
