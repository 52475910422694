import React from 'react';
import Cookies from 'universal-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import MySnack from '../components/MySnack';
import MyLoading from '../components/MyLoading';
import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import Admin from '../components/Admin';
import RegistrasiPage from './Siswa/RegistrasiPage';
import MyVerification from './MyVerification';
import Home from '../components/HomePage/Home';

const cookies = new Cookies();
const PrivateRoute = (prop) => (
  <div>
    <Route
      path="/"
      render={(props) =>
        cookies.get('token') ? (
          <Admin {...prop} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  </div>
);

const MyRouter = (greaterProps) => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => (
            <SignIn query={greaterProps.client.query} {...props} />
          )}
        />
        <Route
          exact
          path="/registrasi"
          render={(props) => (
            <RegistrasiPage
              query={greaterProps.client.query}
              mutate={greaterProps.client.mutate}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/sign_up"
          render={(props) => (
            <SignUp
              mutate={greaterProps.client.mutate}
              query={greaterProps.client.query}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/verification/:uid"
          render={(props) => (
            <MyVerification mutate={greaterProps.client.mutate} {...props} />
          )}
        />
        <Route exact path="/login" render={(props) => <Home {...props} />} />
        <PrivateRoute
          query={greaterProps.client.query}
          mutate={greaterProps.client.mutate}
        />
      </Switch>
      <MySnack {...greaterProps.snack} />
      {greaterProps.globalLoading && <MyLoading />}
    </Router>
  );
};

export default MyRouter;
